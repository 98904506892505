import { API_BASE_URL } from '../constants'
import axios from 'axios'

export const register = (data) => axios.post(`${API_BASE_URL}/register`, data)
export const login = (data) => axios.post(`${API_BASE_URL}/login`, data)
export const logout = () => axios.post(`${API_BASE_URL}/logout`)
// export const verify = (data) => axios.post(`${API_BASE_URL}/auth/verify`, data)

export const userData = () => axios.get(`${API_BASE_URL}/api/user`)

// Migration Data
export const getMigrations = () => axios.get(`${API_BASE_URL}/api/migration`)
export const getMigration = ( id ) => axios.get(`${API_BASE_URL}/api/migration/${id}`)
export const createMigration = (data) => axios.post(`${API_BASE_URL}/api/migration`, data)

export const connectAccount = (id, accountType, data) => axios.put(`${API_BASE_URL}/api/migration/${id}/${accountType}`, data);
export const prevalidateAccount = (id) => axios.post(`${API_BASE_URL}/api/migration/${id}/validation/prevalidate`);
export const retryPrevalidateAccount = (id) => axios.put(`${API_BASE_URL}/api/migration/${id}/validation/prevalidate`);
export const getPrevalidationStatus = (id) => axios.get(`${API_BASE_URL}/api/migration/${id}/validation/prevalidate`);
export const getDataTotals = (id) => axios.get(`${API_BASE_URL}/api/migration/${id}/validation/totals`);
export const confirmDataTotals = (id) => axios.put(`${API_BASE_URL}/api/migration/${id}/validation/totals`);

export const getWorkspaces = (id) => axios.get(`${API_BASE_URL}/api/migration/${id}/workspaces`);
export const updateWorkspaceMapping = (id, workspaceId, data) => axios.put(`${API_BASE_URL}/api/migration/${id}/workspaces/${workspaceId}`, data);
export const getWorkspaceBoards = (migrationId, workspaceId) => axios.get(`${API_BASE_URL}/api/migration/${migrationId}/workspaces/${workspaceId}/boards`);
export const updateWorkspaceBoardMapping = (migrationId, workspaceId, boardId, data) => axios.put(`${API_BASE_URL}/api/migration/${migrationId}/workspaces/${workspaceId}/boards/${boardId}`, data);
export const getWorkspaceBoardColumns = (migrationId, workspaceId, boardId) => axios.get(`${API_BASE_URL}/api/migration/${migrationId}/workspaces/${workspaceId}/boards/${boardId}/columns`);
export const updateBoardColumnMapping = (migrationId, workspaceId, boardId, columnId, data) => axios.put(`${API_BASE_URL}/api/migration/${migrationId}/workspaces/${workspaceId}/boards/${boardId}/columns/${columnId}`, data);

export const beginMigration = (id) => axios.post(`${API_BASE_URL}/api/migration/${id}/migrate/workspaces`);
export const migrateItems = (id) => axios.post(`${API_BASE_URL}/api/migration/${id}/migrate/items`);

export const getColumns = (id) => axios.get(`${API_BASE_URL}/api/migration/${id}/columns`);
export const getRequiredColumns = (id) => axios.get(`${API_BASE_URL}/api/migration/${id}/columns/required`);
export const getSecondaryColumns = (id) => axios.get(`${API_BASE_URL}/api/migration/${id}/columns/secondary`);
export const updateColumnMapping = (migrationId, columnId, data) => axios.put(`${API_BASE_URL}/api/migration/${migrationId}/columns/${columnId}`, data);
export const setColumnAsChecked = (migrationId, columnId, data) => axios.put(`${API_BASE_URL}/api/migration/${migrationId}/columns/${columnId}/created`, data);