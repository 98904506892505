import { Form, Alert, Image, Spinner, InputGroup } from 'react-bootstrap'
import { Button } from "monday-ui-react-core";
import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import { API_BASE_URL, LOGIN_LINK} from '../../../constants'
import styles from './index.module.css';
import { AiOutlineMail, AiOutlineKey, AiOutlineUser } from 'react-icons/ai'
import { register} from '../../../server'
import axios from 'axios'
axios.defaults.withCredentials = true;
axios.defaults.baseURL = API_BASE_URL;

function Register() {

    const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
    const [passwordConfirmation, setPasswordConfirmation] = useState('')
    const [firstame, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')

	const [isLoading, setIsLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')

	let navigate = useNavigate()

    useEffect(() => {
        axios.get(API_BASE_URL+'/sanctum/csrf-cookie').catch((err) => {
            setErrorMessage("Failed to connect to server.")
        });
    }, []);

	const handleRegister = async (event) => {
		event.preventDefault()
        setFirstname(firstame.trim())
        setLastname(lastname.trim())
        setEmail(email.trim())
		setPassword(password.trim())
        setPasswordConfirmation(passwordConfirmation.trim())
		setErrorMessage("")

        const registerData = {
			email: email,
			password: password,
            password_confirmation: passwordConfirmation,
            firstname: firstame,
            lastname: lastname
		}

		try {
			setIsLoading(true)
			// const { data: { user, token } } = await login(loginData)

       
            register(registerData).then(res => {
                if (res.status === 201) {
                    navigate(LOGIN_LINK);
                } else {
                    setErrorMessage(res.data.message)
                    setIsLoading(false)
                }
            }).catch((err) => {
                let errorMsg = err?.response?.data?.message || "Failed to connect to server."
                setErrorMessage(errorMsg)                    
                setIsLoading(false)
            })   
		} catch (err) {
			let errorMsg = err?.response?.data?.message || "Failed to connect to server."
			setErrorMessage(errorMsg)
			setIsLoading(false)
		}
	}

	return (
		<div className={styles.container}>
			<div>
                <Image src='/logo.png' className={styles.logo}/>
			</div>

            <div className={styles.formContainer}>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>First Name</Form.Label>
                        <InputGroup className="mb-3">
                            <span className={styles.formTextIcon}><AiOutlineUser /></span>
                            <Form.Control autoComplete="off" className={styles.formText} type="text" placeholder="First Name" value = { firstame } onChange = { (event) => { setFirstname(event.target.value) } }/>
                        </InputGroup>
                        <Form.Label>Last Name</Form.Label>
                        <InputGroup className="mb-3">
                            <span className={styles.formTextIcon}><AiOutlineUser /></span>
                            <Form.Control autoComplete="off" className={styles.formText} type="text" placeholder="Last Name" value = { lastname } onChange = { (event) => { setLastname(event.target.value) } }/>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>E-mail</Form.Label>
                        <InputGroup className="mb-3">
                            <span className={styles.formTextIcon}><AiOutlineMail /></span>
                            <Form.Control autoComplete="off" className={styles.formText} type="text" placeholder="Your Email" value = { email } onChange = { (event) => { setEmail(event.target.value) } }/>
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Password</Form.Label>
                        <InputGroup className="mb-3">
                            <span className={styles.formTextIcon}><AiOutlineKey /></span>
                            <Form.Control autoComplete="off" className={styles.formText} type="password" placeholder="Your Password" value = { password } onChange = { (event) => { setPassword(event.target.value) } }/>
                        </InputGroup>
                        <Form.Label>Password Confirmation</Form.Label>
                        <InputGroup className="mb-3">
                            <span className={styles.formTextIcon}><AiOutlineKey /></span>
                            <Form.Control autoComplete="off" className={styles.formText} type="password" placeholder="Confirm Your Password" value = { passwordConfirmation } onChange = { (event) => { setPasswordConfirmation(event.target.value) } }/>
                        </InputGroup>
                    </Form.Group>

                    <Form.Group>
                        <div className={styles.submitButtonContainer}>
                            {errorMessage !== "" ? 
                                <Alert className={styles.errorAlert} variant="danger">
                                    <span>{errorMessage}</span>
                                </Alert>
                            : ""}

                            <Button onClick={ (event) => handleRegister(event) }>
                                { isLoading ? <>Loading <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/></>: "Register" }
                            </Button>
                        </div>
                    </Form.Group>

                    <Form.Group>
                        Already have and account?<Link to={LOGIN_LINK} className={styles.forgotPasswordLink}>Login</Link>
                    </Form.Group>    
                </Form>
            </div>
		</div>
	);
}

export default Register;
