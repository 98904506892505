export const API_BASE_URL = 'https://api.migration.mapps.luxie.tech';
// export const API_BASE_URL = 'http://localhost:8000';

export const REGISTER_LINK = "/register"
export const LOGIN_LINK = "/login"
export const RESET_PASSWORD_LINK = "/reset-password"
export const MIGRATIONS_LINK = "/migrations"
export const MIGRATION_LINK = "/migrations/:id"
export const CONNECT_MIGRATION_LINK = "/migrations/:id/connect"
export const PREVALIDATE_MIGRATION_LINK = "/migrations/:id/prevalidate"
export const MAP_WORKSPACES_LINK = "/migrations/:id/map/workspaces"
export const MAP_WORKSPACE_LINK = "/migrations/:id/map/workspaces/:workspaceId"
export const MAP_BOARD_LINK = "/migrations/:id/map/workspaces/:workspaceId/boards/:boardId"
export const MAP_COLUMNS_LINK = "/migrations/:id/map/columns"
export const MAP_SECONDARY_COLUMNS_LINK = "/migrations/:id/map/secondary-columns"