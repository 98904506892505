import { useState, useEffect, useCallback, useMemo } from 'react';
import { Button, LinearProgressBar } from "monday-ui-react-core";
import Swal from 'sweetalert2';
import { Spinner} from 'react-bootstrap'
import { confirmDataTotals, getDataTotals, getMigration, getPrevalidationStatus, prevalidateAccount, retryPrevalidateAccount } from '../../../server'
import DataTable from '../../common/data-table';
import { useParams, useNavigate } from 'react-router';
import DataTotalModal from '../../modals/data-total/DataTotalModal';
import { useSearchParams } from 'react-router-dom';
import ManualNav from '../../common/navs/manual-nav';

const Prevalidate = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const [searchParams, ] = useSearchParams();
    const manualNav = searchParams.get('manualnav');

    const [migration, setMigration] = useState(null);
    const [validationProgress, setValidationProgress] = useState(0);
    const [dataTotals, setDataTotals] = useState(null);
    const [manualColumnTotals, setManualColumnTotals] = useState(null);
    const [boardViewTotals, setBoardViewTotals] = useState(null);
    const [showBoardViewTotals, setShowBoardViewTotals] = useState(false);
    const [showManualColumnTotals, setShowManualColumnTotals] = useState(false);
    const [isFetching, setIsFetching] = useState(true);
    const [hasFailed, setHasFailed] = useState(false);

    const getPrevalidationProgress = useCallback(() => {
        getPrevalidationStatus(id).then((res) => {
            if (res.status === 200) {
                setValidationProgress(res.data.progress);
                setIsFetching(false);
            } else {
                showAlert('error', `Error attempting to get account validation progress: ${res.data.message}`);
            }
        }).catch((err) => {
            if (err.response.status === 400 && err.response.data.error === 'MIGRATION_CANCELLED') {
                setHasFailed(true);
            } else {
                showAlert('error', err.response.data.message);
            }
        });
    }, [id]);

    const getTotals = useCallback(() => {
        getDataTotals(id).then((res) => {
            if (res.status === 200) {
                setDataTotals(res.data.totals);
                setBoardViewTotals(res.data.boardviews);
                setManualColumnTotals(res.data.manual_columns);
                setIsFetching(false);
            } else {
                showAlert('error', `An error has occured, please try again later.`);
            }
        });
    }, [id]);
    

    useEffect(() => {
        getMigration(id).then(res => {
            setMigration(res.data);

            if (!manualNav) {
                if (res.data.status === 'pre_validation') {
                    getPrevalidationProgress();
                } else if (res.data.status === 'validated') {
                    getTotals();
                } else if (res.data.status !== 'connected' && res.data.status !== 'source_connected' && res.data.status !== 'failed') {
                    navigate(`/migrations/${id}`)
                }
            } else {
                if (res.data.pre_migration_completed === 1) {
                    getTotals()
                } else {
                    getPrevalidationProgress();
                }
            }
        }).catch((err) => {
            showAlert('error', err.response.data.message);
        });
    }, [id, getPrevalidationProgress, getTotals, navigate, manualNav]);

    const beginValidation = () => {
        prevalidateAccount(id).then((res) => {
            if (res.status === 200) {
                showAlert('success', res.data.message).then(() =>{
                    navigate(`/migrations/${id}`);
                });
            } else {
                showAlert('error', `Error attempting to start account validation: ${res.data.message}`);
            }
        });
    }

    const retryValidation = () => {
        retryPrevalidateAccount(id).then((res) => {
            if (res.status === 200) {
                showAlert('success', res.data.message).then(() =>{
                    navigate(`/migrations/${id}`);
                });
            } else {
                showAlert('error', `Error attempting to start account validation: ${res.data.message}`);
            }
        });
    }

    const showAlert = (icon, message) => {
        return Swal.fire({
            icon: icon,
            html: message,
            allowOutsideClick: false
        });
    };

    const columns = useMemo(() => [
        {
            isSortable: true,
            Header: 'Type',
            accessor: 'count_type',
        },
        {
            Header: 'Source Total',
            accessor: 'total',
        },
    ], []);

    const handleConfirm = () => {
        confirmDataTotals(id).then((res) => {
            if (res.status === 200) {
                navigate(`/migrations/${id}/map/workspaces`)
            } else {
                showAlert('error', res.data.message ? res.data.message : `An error has occured, please try again later.`);
            }
        });
    }

    return (
        <>
            <ManualNav migration={migration} />
            {isFetching && !migration ? (
                <div className='loading-container'>
                    <Spinner color='#fff' height={80} width={80} />
                </div>
            ) : hasFailed ? (
                <div>
                    <h3>Prevalidation Failed</h3>
                    <Button onClick={() => retryValidation()} >Retry Validation</Button>
                </div>
            ) : (
                migration.status === 'connected' || migration.status === 'source_connected' ? (
                    <Button onClick={() => beginValidation()} >Begin Validation</Button>
                ) : migration.status === 'pre_validation' ? (
                    <>
                        <h3>Pre Validation Progress</h3>
                        
                        <div className="linear-progress-bar_column">
                            <div className="linear-progress-bar_block">
                                <LinearProgressBar indicateProgress value={validationProgress} size={LinearProgressBar.sizes.LARGE} />
                            </div>
                        </div>
                    </>
                ) : dataTotals ? (
                    <>

                        <DataTotalModal
                            show={showBoardViewTotals}
                            setShowModal={setShowBoardViewTotals}
                            data={boardViewTotals}
                            title='Board View Totals'
                        />

                        <DataTotalModal
                            show={showManualColumnTotals}
                            setShowModal={setShowManualColumnTotals}
                            data={manualColumnTotals}
                            title='Manual Column Totals'
                        />

                        <h3>Prevalidation Totals</h3>
                        <DataTable columns={columns} data={dataTotals} />

                        {boardViewTotals && (
                            <>
                                <Button onClick={() => setShowBoardViewTotals(true)} >Show Board View Totals</Button>
                            </>
                        )}

                        {manualColumnTotals && (
                            <>
                                <Button onClick={() => setShowManualColumnTotals(true)} >Show Manual Column Totals</Button>
                            </>
                        )}

                        <Button onClick={() => retryValidation()} >Retry Validation</Button>

                        
                        {migration.destination_account_id && migration.destination_user_id ? (
                            <Button onClick={() => handleConfirm()} >Confirm</Button>
                        ) : 
                            <Button onClick={() => navigate(`/migrations/${id}/connect`)} >Connect Source Account</Button>
                        }
                        

                    </>
                ) : (
                    migration.status === 'failed' ? (
                        <Button onClick={() => retryValidation()} >Retry Validation</Button>
                    ) : null )

            )}
        </>
    );
};

export default Prevalidate;
