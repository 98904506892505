import React from 'react';
import './App.css'
import ContentWrapper from './components/common/content-wrapper'
import Login from './components/pages/login'
import ProtectedRoute from './components/common/protected-route'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import { LOGIN_LINK, REGISTER_LINK } from './constants'
import axios from 'axios'
import UserProvider from './context/user-context'
import Register from './components/pages/register';

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response.status === 401) {
    localStorage.removeItem('user');
    localStorage.setItem('return_url', window.location.pathname + window.location.search);
    window.location.href = LOGIN_LINK
  }
  return Promise.reject(error)
})

function App() {

  return (
    <BrowserRouter>
      <UserProvider>
            <main>
              <Routes>
                <Route path={REGISTER_LINK} element={ <Register/> } />
                <Route path={LOGIN_LINK} element={ <Login/> } />
                <Route path="*" element={ <ProtectedRoute><ContentWrapper/></ProtectedRoute> } />
              </Routes>
            </main>
      </UserProvider>
    </BrowserRouter>
	)
}

export default App;
