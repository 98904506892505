import { useState, useEffect } from 'react';
import { Button } from "monday-ui-react-core";
import { Spinner} from 'react-bootstrap'
import { getMigration } from '../../../server'
import { useNavigate, useParams } from 'react-router';
import ConnectAccountModal from '../../modals/connect-account/ConnectAccountModal';

const ConnectMigration = () => {

    const navigate = useNavigate();
    const { id } = useParams();

    const [migration, setMigration] = useState(null);
    const [isFetching, setIsFetching] = useState(true);
    const [isConnecting, setIsConnecting] = useState(false)
    const [accountType, setAccountType] = useState('source');

    useEffect(() => {
        getMigration(id).then(res => {
            setMigration(res.data);
            setIsFetching(false);

            if (res.data.status !== 'pending_connection' && res.data.status !== 'connected' && res.data.status !== 'source_connected' && res.data.status !== 'pre_validation' && res.data.status !== 'validated') {
                navigate(`/migrations/${id}`);
            }
        });
    }, [isFetching, id, navigate]);

    return (
        <>
            <ConnectAccountModal
                show={isConnecting}
                setConnectAccount = {setIsConnecting}
                accountType = {accountType}
                setMigrationUpdating = {setIsFetching}
                id = {id}
            />

            {isFetching && !migration ? (
                <div className='loading-container'>
                    <Spinner color='#fff' height={80} width={80} />
                </div>
            ) : (
                <table>
                    <tr><td><h2>Source</h2></td><td><h2>Destination</h2></td></tr>

                    <tr>
                        <td>
                            <div>
                                {migration.source_account_id ?
                                    <>
                                        <div>
                                            <p>Account Name: {migration.source_account_name}</p>
                                            <p>Account Slug: {migration.source_account_slug}</p>
                                        </div>
                                        <div>
                                            <Button onClick={() => { setAccountType('source');setIsConnecting(true)} } >Connect new account </Button>
                                        </div>
                                        </>
                                : <Button onClick={() => { setAccountType('source');setIsConnecting(true)} }>Connect</Button>}
                            </div>
                        </td>
                        <td>
                            <div>
                                {migration.destination_account_id ? (
                                    <>
                                        <div>
                                            <p>Account Name: {migration.destination_account_name}</p>
                                            <p>Account Slug: {migration.destination_account_slug}</p>
                                        </div>
                                        <div>
                                            <Button onClick={() => { setAccountType('destination');setIsConnecting(true)} } >Connect new account </Button>
                                        </div>
                                        </>
                                )
                                : <Button onClick={() => { setAccountType('destination');setIsConnecting(true)} }>Connect</Button>}
                            </div>
                        </td>

                    </tr>

                    {migration.source_account_name && migration.source_account_slug ? (
                        <tr><td colSpan={2}>
                            <Button onClick={() => navigate(`/migrations/${id}/prevalidate`)} >Prevalidate</Button>
                        </td></tr>
                        ) : null }

                </table>
            )}
        </>
    );
};

export default ConnectMigration;
