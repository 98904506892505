import React, { useMemo } from 'react';
import DataTable from '../../common/data-table';
import { Modal } from 'react-bootstrap';
import { Button } from 'monday-ui-react-core';

const DataTotalModal = ({ show, setShowModal, data, title}) => {

    const onClose = () => {
        setShowModal(false)
    }

    const columns = useMemo(() => [
        {
            isSortable: true,
            Header: 'Type',
            accessor: 'count_type',
        },
        {
            Header: 'Source Total',
            accessor: 'source_total',
        },
    ], []);

    return (
        <Modal show={show} size='md' backdrop='static' closebutton={true}>
            <Modal.Header>{title}</Modal.Header>
            <Modal.Body>
                <DataTable columns={columns} data={data} />
            </Modal.Body>
            <Modal.Footer>
                <Button color={Button.colors.NEGATIVE} onClick={onClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DataTotalModal;
