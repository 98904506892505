import { useState, useEffect, useCallback, useMemo } from 'react';
import { Button, TextField } from "monday-ui-react-core";
import Swal from 'sweetalert2';
import { Spinner} from 'react-bootstrap'
import { beginMigration, getMigration,getWorkspaces, updateWorkspaceMapping} from '../../../server'
import DataTable from '../../common/data-table';
import { useParams, useNavigate } from 'react-router';
import FunctionOverrideModal from '../../modals/function-override/FunctionOverride';
import { useSearchParams } from 'react-router-dom';
import ManualNav from '../../common/navs/manual-nav';

const MapWorkspaces = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const [searchParams, ] = useSearchParams();
    const manualNav = searchParams.get('manualnav');

    const [migration, setMigration] = useState(null);
    const [workspaces, setWorkspaces] = useState(null);
    const [isFetching, setIsFetching] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [showOverride, setShowOverride] = useState(false);

    const getMigrationWorkspaces = useCallback(() => {
        getWorkspaces(id).then((res) => {
            if (res.status === 200) {
                setWorkspaces(res.data.workspaces);
                setIsFetching(false);
            } else {
                showAlert('error', `An error has occured, please try again later.`);
            }
        });
    }, [id]);
    

    useEffect(() => {
        getMigration(id).then(res => {
            setMigration(res.data);

            if (!manualNav) {
                if (res.data.status === 'pending_user_link') {
                    getMigrationWorkspaces();
                } else {
                    navigate(`/migrations/${id}`)
                }
            } else {
                getMigrationWorkspaces();
            }
        });
    }, [id, getMigrationWorkspaces, navigate, manualNav]);

    const showAlert = (icon, message) => {
        return Swal.fire({
            icon: icon,
            html: message,
            allowOutsideClick: false
        });
    };

    const handleConfirm = () => {
        // TODO: Alert user that errors will occur if their mapping is not correct

        Swal.fire({
            title: 'Would you like to continue?',
            showDenyButton: true,
            showConfirmButton: true,
            confirmButtonText: 'Continue',
            denyButtonText: 'Cancel',
            html: 'Please confirm that you have mapped all existing workspaces and workspace products correctly. Failure to do may cause duplicates to be created.'
        }).then((result) => {
            setIsLoading(true);
            if (result.isConfirmed) {
                beginMigration(id).then(res => {
                    if (res.status === 200) {
                        navigate(`/migrations/${id}`)
                    } else {
                        setIsLoading(false);
                        showAlert('error', `An error has occured, please try again later.`);
                    }
                }).catch(err => {
                    setIsLoading(false);
                    if (err.response.status === 400) {
                        showAlert('error', `An error has occured, please try again later.`);
                    }
                });
            }
        });

        
    }

    const columns = useMemo(() => [
        {
            id: 'select',
            style: { width: 80 },
            Cell: () => {
                
                return (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flex: '1 1 auto',
                        }}
                    >
                        
                    </div>
                );
            },
        },
        {
            Header: 'Workspace Name',
            accessor: 'source_name',
        },
        {
            isSortable: true,
            Header: 'Type',
            accessor: 'product_type',
            Cell: (props) => {    
                const type = props.row.original.product_type;               
                if (type === 'crm') {
                    return "Sales CRM"
                } else if (type === 'dev') {
                    return "Dev"
                }
            },
        },
        {
            Header: 'Source Workspace ID',
            accessor: 'source_id',
            Cell: (props) => {
                const workspaceId = props.row.original.source_id;
                return (
                    migration.source_account_slug ? <a href={`https://${migration.source_account_slug}.monday.com/workspaces/${workspaceId}`} target="_blank" rel="noreferrer">{workspaceId}</a> : null
                )
            }

        },
        {
            Header: 'Destination Workspace ID',
            accessor: 'destination_id',
            // Cell: (props) => {
            //     const workspaceId = props.row.original.destination_id;
            //     return (
            //         migration.destination_account_slug && workspaceId ? <a href={`https://${migration.destination_account_slug}.monday.com/workspaces/${workspaceId}`} target="_blank" rel="noreferrer">{workspaceId}</a> : null
            //     )
            // }
            Cell: (props) => {
                const workspaceId = props.row.original.id;
                return (
                    <TextField value={props.row.original.destination_id} onBlur={(event) => {
                        const data = {
                            destination_id: event.target.value
                        }
                        updateWorkspaceMapping(id, workspaceId,data).catch(err => {
                            showAlert('error', `An error has occured updating the destination workspace id for ${props.row.original.source_name}. Please try again later.`)
                        })   
                    }} />
                )
            }

        },
        {
            id: 'action',
            style: { width: '120px' },
            Cell: (props) => {
                const workspaceId = props.row.original.id;
                    return (
                    <div>
                        <Button color={Button.colors.PRIMARY} onClick = {() => {navigate(`/migrations/${id}/map/workspaces/${workspaceId}` + (manualNav ? '?manualnav=true' : '' ) )}}>
                            Map Boards
                        </Button>
                    </div>
                )
            },
        },
    ], [migration, navigate, id, manualNav]);

    return (
        <>
            <ManualNav migration={migration} />
            <FunctionOverrideModal
                show = {showOverride}
                setShowModal = {setShowOverride}
                migrationId = {id}
            />

            {isFetching && !migration ? (
                <div className='loading-container'>
                    <Spinner color='#fff' height={80} width={80} />
                </div>
            ) : (
                migration && workspaces ? (
                    <>
                        <h3>Product Workspaces</h3>
                        <DataTable columns={columns} data={workspaces} />
                        <Button disabled={isLoading} onClick={() => handleConfirm()} >Confirm workspace mapping and begin migration</Button>
                    </>
                ) : null
            )}

            <Button onClick={() => setShowOverride(true)} >Override Migration</Button>
        </>
    );
};

export default MapWorkspaces;
