// import styles from './index.module.css';
import { useState, useEffect } from 'react';
import { Spinner} from 'react-bootstrap'
import { Button } from "monday-ui-react-core";
import { getMigration } from '../../../server'
import { useParams, useNavigate } from 'react-router';
import ManualNav from '../../common/navs/manual-nav';
import { useSearchParams } from 'react-router-dom';

const Migration = () => {

    const { id } = useParams();
    const [searchParams, ] = useSearchParams();
    const manualNav = searchParams.get('manualnav');
    const navigate = useNavigate()

    const [migration, setMigration] = useState(null);
    const [isFetching, setIsFetching] = useState(true);

    useEffect(() => {
        getMigration(id).then(res => {
            if (manualNav) {
                if (res.data.status === 'pending_connection') {
                    navigate(`/migrations/${id}/connect`);
                } else if (res.data.status === 'pre_validation' || res.data.status === 'validated' || res.data.status === 'connected') {
                    navigate(`/migrations/${id}/prevalidate`);
                } else if (res.data.status === 'pending_user_link') {
                    navigate(`/migrations/${id}/map/workspaces`)
                } else if (res.data.status === 'migrating') { 
                    if (res.data.workspaces_status === 'migrated' && res.data.boards_status === 'migrated' && res.data.items_status === 'awaiting_columns_mapped') {
                        navigate(`/migrations/${id}/map/columns`);
                    }
                }
            }

            setMigration(res.data);
            setIsFetching(false);
        });
    }, [isFetching, id, navigate, manualNav]);

       return (

                <div>
                    <ManualNav migration={migration} />
                    {isFetching && !migration ? (
                        <div className='loading-container'>
                            <Spinner color='#fff' height={80} width={80} />
                        </div>
                    // ) : migration.status === 'validated' ? (
                    //     <>
                    //         <div className={styles.contentContainer} hidden={migration ? false : true}>
                    //             <p>Prevalidation Totals</p>
                    //         </div>
                    //     </>
                    ) : (
                        migration.source_monday_token || migration.status === 'failed' ? (
                            <Button color={Button.colors.PRIMARY} onClick = {() => {navigate(`/migrations/${id}/prevalidate`)}}>
                                Prevalidate
                            </Button>
                        ) : null
                    )}
                </div>
            );
        };

        export default Migration;
