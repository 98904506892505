import { useState, useEffect, useCallback, useMemo } from 'react';
import { Button, TextField } from "monday-ui-react-core";
import Swal from 'sweetalert2';
import { Spinner} from 'react-bootstrap'
import { getMigration, getWorkspaceBoardColumns, updateBoardColumnMapping } from '../../../server'
import DataTable from '../../common/data-table';
import { useParams, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import ManualNav from '../../common/navs/manual-nav';

const MapBoard = () => {

    const { id, workspaceId, boardId } = useParams();
    const navigate = useNavigate();
    const [searchParams, ] = useSearchParams();
    const manualNav = searchParams.get('manualnav');

    const [migration, setMigration] = useState(null);
    const [boardColums, setBoardColumns] = useState(null);
    const [boardName, setBoardName] = useState('');
    const [isFetching, setIsFetching] = useState(true);

    const getMigrationBoardColumns = useCallback(() => {
        getWorkspaceBoardColumns(id, workspaceId, boardId).then((res) => {
            if (res.status === 200) {
                setBoardColumns(res.data.columns);
                setBoardName(res.data.board.source_name)
                setIsFetching(false);
            } else {
                showAlert('error', `An error has occured, please try again later.`);
            }
        });
    }, [id, workspaceId, boardId]);
    

    useEffect(() => {
        getMigration(id).then(res => {
            setMigration(res.data);

            if (!manualNav) {
                if (res.data.status === 'pending_user_link') {
                    getMigrationBoardColumns();
                } else {
                    navigate(`/migrations/${id}`)
                }
            } else {
                getMigrationBoardColumns();
            }
        });
    }, [id, getMigrationBoardColumns, navigate, manualNav]);

    const showAlert = (icon, message) => {
        return Swal.fire({
            icon: icon,
            html: message,
            allowOutsideClick: false
        });
    };

    const returnToBoardList = () => {
        navigate(`/migrations/${id}/map/workspaces/${workspaceId}` + (manualNav ? '?manualnav=true' : ''));
    }

    const columns = useMemo(() => [
        {
            id: 'select',
            style: { width: 80 },
            Cell: () => {
                
                return (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flex: '1 1 auto',
                        }}
                    >
                        
                    </div>
                );
            },
        },
        {
            Header: 'Board Name',
            accessor: 'source_name',
        },
        {
            Header: 'Source Column ID',
            accessor: 'source_id',
            Cell: (props) => {
                const boardId = props.row.original.source_id;
                return (
                    migration.source_account_slug ? <a href={`https://${migration.source_account_slug}.monday.com/boards/${boardId}`} target="_blank" rel="noreferrer">{boardId}</a> : null
                )
            }

        },
        {
            Header: 'Destination Column ID',
            accessor: 'destination_id',
            Cell: (props) => {
                const columnId = props.row.original.id;
                return (
                    <TextField value={props.row.original.destination_id} onBlur={(event) => {
                        const data = {
                            destination_id: event.target.value
                        }
                        updateBoardColumnMapping(id, workspaceId, boardId, columnId, data).then(res => {
                            // TODO: Show small success icon, don't stop user from entering another value
                        }).catch(err => {
                            showAlert('error', `An error has occured updating the destination column id for ${props.row.source_name}. Please try again later.`)
                        });
                    }} />
                )
            }

        },
    ], [migration, boardId, workspaceId, id]);

    return (
        <>
            <ManualNav migration={migration} />
            {isFetching && !migration ? (
                <div className='loading-container'>
                    <Spinner color='#fff' height={80} width={80} />
                </div>
            ) : (
                migration && boardColums ? (
                    <>
                        <h3>Board - {boardName}</h3>
                        <DataTable columns={columns} data={boardColums} />
                        <Button onClick={() => returnToBoardList()} >Return to board list</Button>
                    </>
                ) : null
            )}
        </>
    );
};

export default MapBoard;
