import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { Button } from 'monday-ui-react-core';
import { connectAccount } from '../../../server';
import Swal from 'sweetalert2';

const ConnectAccountModal = ({ show, setConnectAccount, id, accountType, setMigrationUpdating}) => {

    const [token, setToken] = useState('');

    const handleConnectAccount = () => {
        if (!token) {
            return showAlert('error', `Please enter a token to connect ${accountType} account`);
        }

        const data = { monday_token: token };

        connectAccount(id, accountType, data).then((res) => {
            if (res.status === 200) {
                showAlert('success', `${accountType} account successfully connected`);
                setToken('');
                setConnectAccount(false);
                setMigrationUpdating(true);
            } else {
                showAlert('error', `Error connecting ${accountType} account, please try again`);
            }
        });
    }   

     const showAlert = (icon, message) => {
        return Swal.fire({
            icon: icon,
            html: message,
            allowOutsideClick: false
        });
    };

    const onClose = () => {
        setToken('');
        setConnectAccount(false)
    }

    return (
        <Modal show={show} size='md' backdrop='static' closebutton={true}>
            <Modal.Header>Connect {accountType} Account </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className='mb-3'>
                        <Form.Label>API Token</Form.Label>
                        <Form.Control type="text" value = { token }  autoFocus onChange = { (event) => { setToken(event.target.value) } } placeholder = "monday.com admin API Token"/>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleConnectAccount}>
                    Connect
                </Button>
                <Button color={Button.colors.NEGATIVE} onClick={onClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConnectAccountModal;
