import { useState, useEffect, useCallback, useMemo } from 'react';
import { Button, TextField } from "monday-ui-react-core";
import Swal from 'sweetalert2';
import { Spinner} from 'react-bootstrap'
import { getMigration, getWorkspaceBoards, updateWorkspaceBoardMapping } from '../../../server'
import DataTable from '../../common/data-table';
import { useParams, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import ManualNav from '../../common/navs/manual-nav';

const MapWorkspace = () => {

    const { id, workspaceId } = useParams();
    const navigate = useNavigate();
    const [searchParams, ] = useSearchParams();
    const manualNav = searchParams.get('manualnav');

    const [migration, setMigration] = useState(null);
    const [workspaceBoards, setWorkspaceBoards] = useState(null);
    const [workspaceName, setWorkspaceName] = useState('');
    const [isFetching, setIsFetching] = useState(true);

    const getMigrationWorkspaceBoards = useCallback(() => {
        getWorkspaceBoards(id, workspaceId).then((res) => {
            if (res.status === 200) {
                setWorkspaceBoards(res.data.boards);
                setWorkspaceName(res.data.workspace.source_name);
                setIsFetching(false);
            } else {
                showAlert('error', `An error has occured, please try again later.`);
            }
        });
    }, [id, workspaceId]);

    useEffect(() => {
        getMigration(id).then(res => {
            setMigration(res.data);
            if (!manualNav) {
                if (res.data.status === 'pending_user_link') {
                    getMigrationWorkspaceBoards();
                } else {
                    navigate(`/migrations/${id}`)
                }
            } else {
                getMigrationWorkspaceBoards();
            }
        });
    }, [id, getMigrationWorkspaceBoards, navigate, manualNav]);

    const showAlert = (icon, message) => {
        return Swal.fire({
            icon: icon,
            html: message,
            allowOutsideClick: false
        });
    };

    const returnToWorkspaceList = () => {
        navigate(`/migrations/${id}/map/workspaces` + (manualNav ? '?manualnav=true' : ''));
    }

    const columns = useMemo(() => [
        {
            id: 'select',
            style: { width: 80 },
            Cell: () => {
                
                return (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flex: '1 1 auto',
                        }}
                    >
                        
                    </div>
                );
            },
        },
        {
            Header: 'Board Name',
            accessor: 'source_name',
        },
        {
            Header: 'Source Board ID',
            accessor: 'source_id',
            Cell: (props) => {
                const boardId = props.row.original.source_id;
                return (
                    migration.source_account_slug ? <a href={`https://${migration.source_account_slug}.monday.com/boards/${boardId}`} target="_blank" rel="noreferrer">{boardId}</a> : null
                )
            }

        },
        {
            Header: 'Destination Board ID',
            accessor: 'destination_id',
            Cell: (props) => {
                const boardId = props.row.original.id;
                return (
                    <TextField value={props.row.original.destination_id} onBlur={(event) => {
                        const data = {
                            destination_id: event.target.value
                        }
                        updateWorkspaceBoardMapping(id, workspaceId, boardId, data).catch(err => {
                            showAlert('error', `An error has occured updating the destination board id for ${props.row.original.source_name}. Please try again later.`)
                        })   
                    }} />
                )
            }

        },
        {
            id: 'action',
            style: { width: '120px' },
            Cell: (props) => {
                const boardId = props.row.original.id;
                return (
                    <div>
                        <Button color={Button.colors.PRIMARY} onClick = {() => {navigate(`/migrations/${id}/map/workspaces/${workspaceId}/boards/${boardId}` + (manualNav ? '?manualnav=true' : ''))}}>
                            Map Columns
                        </Button>
                    </div>
                )
            },
        },
        // TODO: Show icon to visulise if all of the columns have correctly been mapped
    ], [migration, id, navigate, workspaceId, manualNav]);

    return (
        <>
            <ManualNav migration={migration} />
            {isFetching && !migration ? (
                <div className='loading-container'>
                    <Spinner color='#fff' height={80} width={80} />
                </div>
            ) : (
                migration && workspaceBoards ? (
                    <>
                        <h3>Product Workspace - {workspaceName}</h3>
                        <DataTable columns={columns} data={workspaceBoards} />
                        <Button onClick={() => returnToWorkspaceList()} >Return to board list</Button>
                    </>
                ) : null
            )}
        </>
    );
};

export default MapWorkspace;
