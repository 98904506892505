import {  Navbar, NavDropdown } from 'react-bootstrap'
import { MIGRATION_LINK, PREVALIDATE_MIGRATION_LINK, MAP_WORKSPACES_LINK, MAP_COLUMNS_LINK, MAP_SECONDARY_COLUMNS_LINK } from '../../../../constants';
import styles from './index.module.css';
import { Link } from 'react-router-dom'

const ManualNav = ({ migration }) => {
    
    return (
        <Navbar collapseOnSelect expand="sm" className={styles.header}>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse className={styles.navbarCollapse}>
                <NavDropdown className={"dropdown-toggle-no-caret"} title={
                    <>
                        <span>Navigate</span>
                    </>
                } align="end">
                    {migration && migration.source_monday_token && (
                        <NavDropdown.Item as={Link} to={MIGRATION_LINK.replace(':id', migration.id) + "?manualnav=true"}>
                            <span>Migration</span>
                        </NavDropdown.Item>
                    )}

                    {migration && migration.source_monday_token && (
                        <NavDropdown.Item as={Link} to={PREVALIDATE_MIGRATION_LINK.replace(':id', migration.id) + "?manualnav=true"}>
                            <span>Prevalidate</span>
                        </NavDropdown.Item>
                    )}
                    {migration && migration.pre_migration_completed === 1 && (
                        <NavDropdown.Item as={Link} to={MAP_WORKSPACES_LINK.replace(':id', migration.id) + "?manualnav=true"}>
                            <span>Migrate Workspaces</span>
                        </NavDropdown.Item>
                    )}
                    {migration && migration.workspaces_status === 'migrated' && (
                        <NavDropdown.Item as={Link} to={MAP_COLUMNS_LINK.replace(':id', migration.id) + "?manualnav=true"}>
                            <span>Map Columns</span>
                        </NavDropdown.Item>
                    )}
                    {migration && migration.workspaces_status === 'migrated' && (
                        <NavDropdown.Item as={Link} to={MAP_SECONDARY_COLUMNS_LINK.replace(':id', migration.id) + "?manualnav=true"}>
                            <span>Map Secondary Columns</span>
                        </NavDropdown.Item>
                    )}
                </NavDropdown>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default ManualNav;