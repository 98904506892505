import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { Button } from 'monday-ui-react-core';
import { createMigration } from '../../../server';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router';


const CreateMigrationModal = ({ show, setCreateMigration }) => {

    const navigate = useNavigate();

    const [name, setName] = useState('');

    const handleCreateMigration = () => {
        if (!name) {
            return showAlert('error', 'Please enter a name for the migration');
        }

        const data = { name };

        createMigration(data).then((res) => {
            if (res.status === 201) {
                showAlert('success', 'Migration created successfully');
                navigate(`/migrations/${res.data.migration.id}`);
            } else {
                showAlert('error', 'Error creating migration, please try again');
            }
        });
    }   

     const showAlert = (icon, message) => {
        return Swal.fire({
            icon: icon,
            html: message,
            allowOutsideClick: false
        });
    };

    const onClose = () => {
        setName('');
        setCreateMigration(false)
    }

    return (
        <Modal show={show} size='md' backdrop='static' closebutton={true}>
            <Modal.Header>Create Migration</Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className='mb-3'>
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" value = { name }  autoFocus onChange = { (event) => { setName(event.target.value) } } placeholder = "Migration Name"/>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleCreateMigration}>
                    Create
                </Button>
                <Button color={Button.colors.NEGATIVE} onClick={onClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateMigrationModal;
