import React from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from 'monday-ui-react-core';
import { beginMigration } from '../../../server';
import { useNavigate } from 'react-router';
import Swal from 'sweetalert2';


const FunctionOverrideModal = ({ show, setShowModal, migrationId }) => {

    const navigate = useNavigate();

    const handleRestartWorkspaces = () => {
        beginMigration(migrationId).then((res) => {
            if (res.status === 200) {
                showAlert('success', 'Migration restarted successfully');
                navigate(`/migrations/${migrationId}`);
            } else {
                showAlert('error', 'Error restarting migration, please try again');
            }
        });
    }

    const onClose = () => {
        setShowModal(false)
    }

    const showAlert = (icon, message) => {
        return Swal.fire({
            icon: icon,
            html: message,
            allowOutsideClick: false
        });
    };

    return (
        <Modal show={show} size='md' backdrop='static' closebutton={true}>
            <Modal.Header>Override Function</Modal.Header>
            <Modal.Body>
                <div>
                    {/* <Button onClick={}>
                        Prevalidate
                    </Button> */}
                    <Button onClick={handleRestartWorkspaces}>
                        Migrate Workspaces
                    </Button>
                    {/* <Button onClick={}>
                        Migrate Items
                    </Button> */}
                    <Button color={Button.colors.NEGATIVE} onClick={onClose}>
                        Close
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default FunctionOverrideModal;
