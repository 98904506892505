import { useState } from 'react';
import Header from '../navs/header';
import Migrations from '../../pages/migrations';
import {
    CONNECT_MIGRATION_LINK,
    MAP_BOARD_LINK,
    MAP_COLUMNS_LINK,
    MAP_SECONDARY_COLUMNS_LINK,
    MAP_WORKSPACES_LINK,
    MAP_WORKSPACE_LINK,
    MIGRATIONS_LINK, MIGRATION_LINK, PREVALIDATE_MIGRATION_LINK,
} from '../../../constants';
import { Routes, Route } from 'react-router-dom';
import styles from './index.module.css';
import Migration from '../../pages/migration';
import ConnectMigration from '../../pages/connect-migration';
import Prevalidate from '../../pages/prevalidate';
import MapWorkspaces from '../../pages/map-workspaces';
import MapWorkspace from '../../pages/map-workspace';
import MapBoard from '../../pages/map-board';
import MapColumns from '../../pages/map-columns';
import MapSecondaryColumns from '../../pages/map-secondary-columns';

const ContentWrapper = () => {
    const [isMenuExpanded, setIsMenuExpanded] = useState(true);

    return (
        <div className={styles.mainWrapper}>
            <Header isMenuExapanded={isMenuExpanded} toggleMenu={setIsMenuExpanded} />
            <div className={styles.mainContentWrapper} >
                <div id='main-content' className={styles.mainContent}>
                    <Routes>
                        <Route exact path='/' element={<Migrations />} />
                        <Route exact path={MIGRATIONS_LINK} element={<Migrations />} />
                        <Route exact path={MIGRATION_LINK} element={<Migration />} />
                        <Route path={CONNECT_MIGRATION_LINK} element={<ConnectMigration />} />
                        <Route path={PREVALIDATE_MIGRATION_LINK} element={<Prevalidate />} />
                        <Route path={MAP_WORKSPACES_LINK} element={<MapWorkspaces />} />
                        <Route path={MAP_WORKSPACE_LINK} element={<MapWorkspace />} />
                        <Route path={MAP_BOARD_LINK} element={<MapBoard />} />
                        <Route path={MAP_COLUMNS_LINK} element={<MapColumns />} />
                        <Route path={MAP_SECONDARY_COLUMNS_LINK} element={<MapSecondaryColumns />} />
                    </Routes>
                </div>
            </div>
        </div>
    );
};

export default ContentWrapper;
