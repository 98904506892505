import styles from './index.module.css';
import DataTable from '../../common/data-table';
import { useMemo, useState, useEffect } from 'react';
import { Button } from "monday-ui-react-core";
import { Spinner} from 'react-bootstrap'
import { getMigrations } from '../../../server'
import { useNavigate } from 'react-router';
import CreateMigrationModal from '../../modals/create-migration/CreateMigrationModal';

const Migrations = () => {

    const navigate = useNavigate()

    const [data, setData] = useState(null);
    // const [selectedItems, setSelectedItems] = useState([]);
    const [isFetching, setIsFetching] = useState(true);
    const [isCreating, setIsCreating] = useState(false)

    useEffect(() => {
        getMigrations().then(res => {
            setData(res.data.migrations);
            setIsFetching(false);
        });
    }, [isFetching]);

    const columns = useMemo(
        () => [
            {
                id: 'select',
                style: { width: 80 },
                Cell: () => {
                    
                    return (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flex: '1 1 auto',
                            }}
                        >
                            
                        </div>
                    );
                },
            },
            {
                isSortable: true,
                Header: 'Title',
                accessor: 'migration_name',
            },
            {
                isSortable: true,
                Header: 'Status',
                accessor: 'status',
            },
            {
                isSortable: true,
                Header: 'Source Account',
                accessor: 'source_account_name',
            },
            {
                isSortable: true,
                Header: 'Source Slug',
                Cell: (props) => {                   
                    return (
                        props.row.original.source_account_slug ? <a href={"https://"+props.row.original.source_account_slug+".monday.com"} target="_blank" rel="noreferrer">{props.row.original.source_account_slug+".monday.com"}</a> : null
                    );
                },
            },
            {
                isSortable: true,
                Header: 'Destination Account',
                accessor: 'destination_account_name',
            },
            {
                isSortable: true,
                Header: 'Destination Slug',
                Cell: (props) => {                   
                    return (
                        props.row.original.destination_account_slug ? <a href={"https://"+props.row.original.destination_account_slug+".monday.com"} target="_blank" rel="noreferrer">{props.row.original.destination_account_slug+".monday.com"}</a> : null
                    );
                },
            },
            {
                id: 'action',
                style: { width: '120px' },
                Cell: (props) => {
                    const id = props.row.original.id;
                    const status = props.row.original.status;

                    if (status === 'pending_connection')  {
                        return (
                            <div>
                                <Button color={Button.colors.PRIMARY} onClick = {() => {navigate(`/migrations/${id}/connect`)}}>
                                    Connect
                                </Button>
                            </div>
                        )
                    } else if (status === 'pre_validation' || status === 'validated') {
                        return (
                            <div>
                                <Button color={Button.colors.PRIMARY} onClick = {() => {navigate(`/migrations/${id}/prevalidate`)}}>
                                    View
                                </Button>
                            </div>
                        );
                    } else {
                        return (
                            <div>
                                <Button color={Button.colors.PRIMARY} onClick = {() => {navigate(`/migrations/${id}`)}}>
                                    View
                                </Button>
                            </div>
                        );
                    }
                },
            },
        ],
        [navigate]
    );

       return (
        <>

            <CreateMigrationModal 
                show = {isCreating}
                setCreateMigration={setIsCreating}
            />

            <div className={styles.headerContainer}>
                <div>
                    <Button color={Button.colors.PRIMARY} onClick={() => setIsCreating(true)}>
                        Create Migration
                    </Button>
                </div>
            </div>
            <div className={styles.contentContainer}>
                {isFetching && !data ? (
                    <div className='loading-container'>
                        <Spinner color='#fff' height={80} width={80} />
                    </div>
                ) : data.length > 0 ? (
                    <>
                        <div  hidden={data.length > 0 ? false : true}>
                            <DataTable columns={columns} data={data} />
                        </div>
                    </>
                ) : (
                    <>
                        <span>No migrations found.</span>
                    </>
                )}
            </div>
        </>
    );
};

export default Migrations;
