import { useState, useEffect, useCallback, useMemo } from 'react';
import { Checkbox, TextField, Button } from "monday-ui-react-core";
import Swal from 'sweetalert2';
import { Spinner} from 'react-bootstrap'
import { getMigration, getRequiredColumns, updateColumnMapping, setColumnAsChecked, migrateItems } from '../../../server'
import DataTable from '../../common/data-table';
import { useParams, useNavigate } from 'react-router';
import FunctionOverrideModal from '../../modals/function-override/FunctionOverride';
import ManualNav from '../../common/navs/manual-nav';
import { useSearchParams } from 'react-router-dom';

const MapColumns = () => {

    const { id } = useParams();
    const [searchParams, ] = useSearchParams();
    const manualNav = searchParams.get('manualnav');
    const navigate = useNavigate();

    const [migration, setMigration] = useState(null);
    const [manualColumns, setColumns] = useState(null);
    const [isFetching, setIsFetching] = useState(true);
    const [showOverride, setShowOverride] = useState(false);

    const getMigrationColumns = useCallback(() => {
        getRequiredColumns(id).then((res) => {
            if (res.status === 200) {
                setColumns(res.data.columns);
                setIsFetching(false);
            } else {
                showAlert('error', `An error has occured, please try again later.`);
            }
        });
    }, [id]);
    

    useEffect(() => {
        getMigration(id).then(res => {
            setMigration(res.data);

            if (!manualNav) {
                if (res.data.status === 'migrating' && (res.data.boards_status === 'migrating' || res.data.boards_status === 'migrated') && res.data.columns_mapped === 0) {
                    getMigrationColumns();
                } else {
                    navigate(`/migrations/${id}`)
                }
            } else {
                getMigrationColumns();
            }
        });
    }, [id, getMigrationColumns, navigate, manualNav]);

    const showAlert = (icon, message) => {
        return Swal.fire({
            icon: icon,
            html: message,
            allowOutsideClick: false
        });
    };

    const handleConfirm = () => {
        // TODO: Alert user that errors will occur if their mapping is not correct
        migrateItems(id).then(res => {
            if (res.status === 200) {
                navigate(`/migrations/${id}`)
            } else {
                showAlert('error', `An error has occured, please try again later.`);
            }
        });
    }

    const columns = useMemo(() => [
        {
            id: 'select',
            style: { width: 80 },
            Cell: () => {
                
                return (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flex: '1 1 auto',
                        }}
                    >
                        
                    </div>
                );
            },
        },
        {
            Header: 'Source Board Name',
            accessor: 'board_name',
            Cell: (props) => {
                const boardId = props.row.original.source_board_id;
                return (
                    migration.source_account_slug ? <a href={`https://${migration.source_account_slug}.monday.com/boards/${boardId}`} target="_blank" rel="noreferrer">{props.row.original.board_name}</a> : props.row.original.board_name
                )
            }

        },
        {
            Header: 'Destination Board',
            accessor: 'destination_board_id',
            Cell: (props) => {
                const boardId = props.row.original.destination_board_id;
                return (
                    migration.destination_account_slug ? <a href={`https://${migration.destination_account_slug}.monday.com/boards/${boardId}`} target="_blank" rel="noreferrer">{props.row.original.destination_board_id}</a> : props.row.original.destination_board_id
                )
            }

        },
        {
            Header: 'Source Name',
            accessor: 'source_name',
            Cell: (props) => {
                const boardId = props.row.original.source_board_id;
                return (
                    migration.source_account_slug ? <a href={`https://${migration.source_account_slug}.monday.com/boards/${boardId}`} target="_blank" rel="noreferrer">{props.row.original.source_name}</a> : props.row.original.source_name
                )
            }

        },
        {
            Header: 'Destination ID',
            accessor: 'destination_id',
            Cell: (props) => {
                const columnId = props.row.original.id;
                if (props.row.original.source_type === 'formula' || props.row.original.source_type === 'lookup' || props.row.original.source_type === 'mirror') {
                    return (

                        <Checkbox checked={props.row.original.created_check} onChange={async (event) => {
                                setColumns(oldColumns => {
                                    const newColumns = [...oldColumns];
                                    const indexOfData = newColumns.findIndex(item => item.id === props.row.original.id);
                                    newColumns[indexOfData] = {...props.row.original, created_check: event.target.checked };
                                    return newColumns;
                                });
                                // Using setTimeout to wait for the setColumns to run first
                                setTimeout(() => {
                                    try {
                                        const data = {
                                            checked: event.target.checked
                                        }
                                        setColumnAsChecked(id, columnId, data)
                                    } catch (error) {
                                        // Rollback state if the error happens
                                        setColumns(oldColumns => {
                                            const newColumns = [...oldColumns];
                                            const indexOfData = newColumns.findIndex(item => item.id === props.row.original.id);
                                            newColumns[indexOfData] = props.row.original;
                                            return newColumns
                                        });
                                        showAlert('error', `An error has occured marking the column as manually created for ${props.row.original.source_id}. Please try again later.`)
                                    }
                                }, 100);
                        }} label={"Mark column as manually created"}/>
                    )
                }
                else {
                    return (
                        <TextField value={props.row.original.destination_id} onBlur={(event) => {
                            const data = {
                                destination_id: event.target.value
                            }
                            updateColumnMapping(id, columnId, data).catch(err => {
                                showAlert('error', `An error has occured updating the destination column id for ${props.row.original.source_id}. Please try again later.`)
                            })   
                        }} />
                    )
                }
            }

        },
    ], [migration, id]);

    return (
        <>
            <ManualNav migration={migration} />
            <FunctionOverrideModal
                show = {showOverride}
                setShowModal = {setShowOverride}
                migrationId = {id}
            />

            {isFetching && !migration ? (
                <div className='loading-container'>
                    <Spinner color='#fff' height={80} width={80} />
                </div>
            ) : (
                migration.status === 'migrating' && manualColumns ? (
                    <>
                        <h3>Columns</h3>
                        <DataTable columns={columns} data={manualColumns} />
                        <Button onClick={() => handleConfirm()} >Confirm column mapping</Button>
                    </>
                ) : null
            )}

            <Button onClick={() => setShowOverride(true)} >Override Migration</Button>
        </>
    );
};

export default MapColumns;
